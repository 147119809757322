<template>
  <div>
    <div class="CPgoodsBox CP" @click="goPages(goods)">
      <!-- /goodsDetail?productId=${goods.productId)} -->
      <!-- <div ></div>/ -->
      <img :src="goods.logo" alt="" class="CPgoodsImg">
      <div class="CPgoodsInfoBox">
        <div class="CPgoodsInfoTitle overflow1">{{ goods.productName }}</div>
        <div class="CPgoodsIntroduce overflow1">{{ goods.ingredient ? goods.ingredient : '' }}{{ goods.color ? ' | ' +
      goods.color : '' }}</div>
        <!-- <div class="CPgoodsPrice overflow1"> <text style="color: #ff5555;"> <text style="zoom:0.78">¥</text> 112112112112</text> /米</div> -->
        <div class="CPgoodsPrice overflow1">
          <div class="overflow1" style="color: #FF5555;display: flex;align-items: baseline;">
            <div style="zoom: 0.78">¥</div>
            <div class="overflow1">{{ goods.maxPrice }}</div>
          </div> /米
        </div>
        <div class="CPgoodsBottom">
          <div class="CPgoodsLabelBox">
            <div class="CPgoodsLabel" v-if="goods.category === '1' || goods.category === '2'"
              :style="{ background: goods.category === '1' ? '#0133ae' : goods.category === '2' ? '#36C1CD' : '' }">{{
      goods.category === '1' ? '现货' : goods.category === '2' ? '定制' : '' }}</div>
            <!-- <div class="CPgoodsLabel" style=" background: #f59643" v-if="goods.isSpecimen === '1'">样品</div> -->
            <div class="CPgoodsLabel" style="background:#f25144 " v-if="isSeckill">秒杀</div>
          </div>
          <img src="@/assets/addGoods.png" alt="" @click.stop="cartAdd(goods.productId)"
            style="width: 30px;height: 30px;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { cartAddApi } from '@/api/api'
export default {
  name: 'goodsMin',
  data () {
    return {};
  },
  props: ['goods', 'isSeckill'],
  created () { },
  mounted () { },
  methods: {
    goPages (e) {
      this.$store.commit('alterTabListIndex', {
        url: '/goodsInfo', i: -1, query: {
          productId: e.productId
        }
      })
    },
    async cartAdd () {
      if (!getToken()) {
        this.$router.push('/login')
        console.log('登录');
        return;
      }
      console.log('goods', this.goods);
      await cartAddApi({ productId: this.goods.productId })
      this.$message({
        message: '添加成功',
        type: 'success'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.CPgoodsBox {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 140px;
  height: 282px;
  background: #FFFFFF;
  // box-shadow: 0px 0px 20px 0px rgba(238, 238, 238, 0.5);

  .CPgoodsImg {
    width: 140px;
    height: 140px;
    background: #F5F5F5;
    border-radius: 10px;
  }

  .CPgoodsInfoBox {
    padding: 10px;

    .CPgoodsInfoTitle {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }

    .CPgoodsIntroduce {
      margin-bottom: 10px;

      font-size: 16px;
      color: #999999;
    }

    .CPgoodsPrice {
      display: flex;
      margin-bottom: 10px;
      font-size: 16px;
      color: #999999;
    }

    .CPgoodsBottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .CPgoodsLabelBox {
        display: flex;
        align-items: center;

        .CPgoodsLabel {
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 24px;
          background: #36C1CD;
          border-radius: 4px;
          font-size: 14px;
          color: #FFFFFF;
        }
      }

    }

  }
}
</style>
