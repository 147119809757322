import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'

import './utils/auth';
import goodsMax from './components/goodsMax/goodsMax.vue';
import goodsMin from './components/goodsMin/goodsMin.vue';

Vue.use(ElementUI);
Vue.component('goodsMax', goodsMax)
Vue.component('goodsMin', goodsMin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
