import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/twoRouterSearch',
    name: 'oneRouter',
    component: () => import('@/views/oneRouter/index.vue'), // 一级路由
    children: [
      {
        path: '/twoRouterSearch',
        redirect: '/index',
        name: 'twoRouterSearch',
        component: () => import('@/views/twoRouterSearch/index.vue'), // 二级路由搜索框
        children: [
          {
            path: '/index',
            name: 'index',
            component: () => import('@/views/index/index.vue'), // 首页
          },
          {
            path: '/search',
            name: 'search',
            component: () => import('@/views/search/index.vue'), // 搜索
          },
          {
            path: '/goodsInfo',
            name: 'goodsInfo',
            component: () => import('@/views/goodsInfo/index.vue'), // 商品详情
          },
          {
            path: '/goodsNeed',
            name: 'goodsNeed',
            component: () => import('@/views/goodsNeed/index.vue'), // 确认报价需求
          },
          {
            path: '/goodsPurchaseEstimate',
            name: 'goodsPurchaseEstimate',
            component: () => import('@/views/goodsPurchaseEstimate/index.vue'), // 商品预估
          },
          {
            path: '/sub',
            name: 'sub',
            component: () => import('@/views/sub/index.vue'), // 提交结果页面
          }
        ]
      },
      {
        path: '/twoRouterTab',
        redirect: '/index',
        name: 'twoRouterTab',
        component: () => import('@/views/twoRouterTab/index.vue'), // 二级路由tab
        children: [
          {
            path: '/shoppingCar',
            name: 'shoppingCar',
            component: () => import('@/views/shoppingCar/index.vue'), // 购物车
          },
          {
            path: '/userCentre',
            name: 'userCentre',
            component: () => import('@/views/userCentre/index.vue'), // 用户中心
          },
          {
            path: '/userEdit',
            name: 'userEdit',
            component: () => import('@/views/userEdit/index.vue'), // 编辑用户信息
          },
          {
            path: '/takeIPLIst',
            name: 'takeIPLIst',
            component: () => import('@/views/takeIPLIst/index.vue'), // 收货地址列表
          },
          {
            path: '/editAndAddIP',
            name: 'editAndAddIP',
            component: () => import('@/views/editAndAddIP/index.vue'), // 编辑 || 新增 地址
          },
          {
            path: '/collect',
            name: 'collect',
            component: () => import('@/views/collect/index.vue'), // 收藏
          },
          {
            path: '/news',
            name: 'news',
            component: () => import('@/views/news/index.vue'), // 消息中心
          },
          {
            path: '/rich',
            name: 'rich',
            component: () => import('@/views/rich/index.vue'), // 富文本
          },
          {
            path: '/orderList',
            name: 'orderList',
            component: () => import('@/views/orderList/index.vue'), // 订单列表
          },
          {
            path: '/orderInfo',
            name: 'orderInfo',
            component: () => import('@/views/orderInfo/index.vue'), // 订单详情
          },
          {
            path: '/pay',
            name: 'pay',
            component: () => import('@/views/pay/index.vue'), // 支付
          },
          {
            path: '/payOffline',
            name: 'payOffline',
            component: () => import('@/views/payOffline/index.vue'), // 线下支付
          },
          {
            path: '/talkDetails',
            name: 'talkDetails',
            component: () => import('@/views/talkDetails/index.vue'), // 洽谈记录
          },
          {
            path: '/editFeedback',
            name: 'editFeedback',
            component: () => import('@/views/editFeedback/index.vue'), // 编辑反馈
          },
          {
            path: '/check',
            name: 'check',
            component: () => import('@/views/check/index.vue'), // 查看物流
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'), // 登录
  }
]
// 解决重复点击报错bug
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  // 路由跳转完成后的操作
  window.scrollTo({
    top: 0
  });
  console.log('路由跳转成功:', to, from);
});
export default router
