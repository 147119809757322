import axios from 'axios';
import { getToken,delToken } from '@/utils/auth'
import { Message } from 'element-ui'
import router from '../router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  // headers: {'X-Custom-Header': 'foobar'}
});
// 请求拦截器
service.interceptors.request.use(
  config => {
    // console.log('请求地址', config.url, config.data ? ('data参数', config.data) : '', config.params ? ('params参数', config.params) : '');
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['content-language'] = 'zh_CN'
    config.headers['clientid'] = 'a909afefc5e4dba4858428764b37ed85'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {

    if (response.data.code === 0 || response.data.code === 401 || response.data.code === 500) {
      console.log('response',response.data);
			if (response.data.code === 401) {
        router.push('/login')
        delToken()
        localStorage.removeItem('userInfo')
        Message({
          type: 'error',
          message: '登录过期，请重新登陆'
        })
			}
			if (response.data.code !== 401) {
				Message({
          type: 'error',
          message: response?.data?.msg
        })
			}

			return Promise.reject(response.data)

    }

    // if (response.data.code)
    return response.data
  },
  error => {
    Message({
      type: 'error',
      message: error?.response?.data.msg
    })
    return Promise.reject(error)
  }
)

export default service