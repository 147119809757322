import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    tabListIndex: 0,
    takeIP: {},
    twoRouterTabIndex: 0
  },
  getters: {
  },
  mutations: {
    /**
     * @param {*} state
     * @param {url:跳转的ulr,i:当前tabListIndex的选中的，query:传递的参数} param1 url不传时则不跳转页面
     */
    alterTabListIndex (state, { url, i=-1, query, name, params }) {
      state.tabListIndex = i
      if (url) {
        router.push({
          path: url,
          query,
          params
        })
      } else if (name) {
        router.push({
          name,
          query,
          params
        })
      }
    },
    editTakeIp (state, obj) {
      state.takeIP = obj
    },
    editTwoRouterTabIndex (state, index = 0) {
      state.twoRouterTabIndex = index
    }
  },
  actions: {
  },
  modules: {
  }
})
