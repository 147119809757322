const key = 'token'
/**
 * 获取token
 * @returns token
 */
export const getToken = () => localStorage.getItem(key)

/**
 * 存token
 * @param {*} newToken 接收到的token
 */
export const setToken = (newToken) => {
  localStorage.setItem(key, newToken)
}

/**
 * 删除token
 */
export const delToken = () => {
  localStorage.removeItem(key)
}