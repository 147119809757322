import request from './request'

/**
* 用户每日首次访问通知
*/
export const firstInformApi = data => {
  return request({
    url: '/appuser/user/firstInform',
    method: 'POST',
    data
  })
}
/**
* APP登录方法
*/
export const appLoginApi = data => {
  return request({
    url: '/auth/appLogin',
    method: 'POST',
    data
  })
}
/**
* APP登录方法
*/
export const smscodeApi = params => {
  return request({
    url: '/resource/sms/code',
    method: 'GET',
    params
  })
}
/**
* APP用户注册
*/
export const appRegisterApi = data => {
  return request({
    url: '/auth/appRegister',
    method: 'POST',
    data
  })
}
/**
* 获取用户信息
*/
export const getInfoApi = data => {
  return request({
    url: '/appuser/user/getInfo',
    method: 'GET',
    data
  })
}
/**
* 查询订单状态
*/
export const getOrderStatusApi = params => {
  return request({
    url: '/apporder/orderInfo/getOrderStatus',
    method: 'GET',
    params
  })
}
/**
* 获取订单支付回调状态
*/
export const payCallBackApi = data => {
  return request({
    url: `/apporder/pay/payCallBack/${data}`,
    method: 'GET'
  })
}
/**
* 获取热销榜入口配置
*/
export const basicsgetProductSalesInAPI = data => {
  return request({
    url: '/appsystem/basics/getProductSalesIn',
    method: 'GET',
    data
  })
}
/**
* 获取热销榜
*/
export const producthotSaleListAPI = params => {
  return request({
    url: '/appprod/product/hotSaleList',
    method: 'GET',
    params
  })
}
/**
* 获取用户现货订单数量
*/
export const getSpotsOrderCountApi = data => {
  return request({
    url: '/appuser/user/getSpotsOrderCount',
    method: 'GET',
    data
  })
}
/**
* 获取获取定制订单数量
*/
export const getCustomizedOrderCountApi = data => {
  return request({
    url: '/appuser/user/getCustomizedOrderCount',
    method: 'GET',
    data
  })
}
/**
* 查询问卷问题列表
*/
export const questionnaireTitleListApi = data => {
  return request({
    url: '/appsystem/questionnaireTitle/list',
    method: 'GET',
    data
  })
}
/**
* 查询问卷问题提交
*/
export const questionnaireInsertApi = data => {
  return request({
    url: '/appuser/questionnaire/insert',
    method: 'POST',
    data
  })
}
/**
* 修改用户资料
*/
export const usereditApi = data => {
  return request({
    url: '/appuser/user/edit',
    method: 'PUT',
    data
  })
}
/**
* 获取城市列表数据
*/
export const cityNameListApi = data => {
  return request({
    url: '/appsystem/region/cityNameList',
    method: 'GET',
    data
  })
}
/**
* 获取城市数据
*/
export const cityListApi = data => {
  return request({
    url: '/appsystem/region/cityList',
    method: 'GET',
    data
  })
}
/**
* 获取区域数据
*/
export const caseListApi = data => {
  return request({
    url: '/appsystem/region/caseList',
    method: 'GET',
    data
  })
}
/**
* 轮播图列表
*/
export const carouselListApi = data => {
  return request({
    url: '/appsystem/carousel/list',
    method: 'GET',
    data
  })
}
/**
* 查询首页产品分类列表
*/
export const typeIndexApi = data => {
  return request({
    url: '/appprod/type/index',
    method: 'GET',
    data
  })
}
/**
* 广告列表
*/
export const advertListApi = data => {
  return request({
    url: '/appsystem/advert/list',
    method: 'GET',
    data
  })
}
/**
* 获取首页产品列表
*/
export const productIndexApi = params => {
  return request({
    url: '/appprod/product/index',
    method: 'GET',
    params
  })
}
/**
* 获取推荐产品列表
*/
export const productRecommendApi = data => {
  return request({
    url: '/appprod/product/recommend',
    method: 'GET',
    data
  })
}
/**
* 查询产品列表
*/
export const productListApi = params => {
  return request({
    url: '/appprod/product/list',
    method: 'GET',
    params
  })
}
/**
* 新增购物车
*/
export const cartAddApi = data => {
  return request({
    url: '/apporder/cart/add',
    method: 'POST',
    data
  })
}
/**
* 获取产品详细信息
*/
export const productDetailApi = data => {
  return request({
    url: `/appprod/product/detail/${data}`,
    method: 'GET',
  })
}
/**
* 新增产品收藏
*/
export const productCollectAddApi = data => {
  return request({
    url: '/appprod/productCollect/add',
    method: 'POST',
    data
  })
}
/**
* 新增产品取消收藏
*/
export const productCollectcancelApi = data => {
  return request({
    url: `/appprod/productCollect/cancel/${data}`,
    method: 'POST',
  })
}
/**
* 查询购物车列表
*/
export const apporderListApi = () => {
  return request({
    url: `/apporder/cart/list`,
    method: 'GET',
  })
}
/**
* 删除购物车
*/
export const cartDeleteApi = data => {
  return request({
    url: `/apporder/cart/delete/${data}`,
    method: 'POST'
  })
}
/**
* 查询产品级联分类列表
*/
export const typecaseListApi = data => {
  return request({
    url: `/appprod/type/caseList`,
    method: 'GET',
    data
  })
}
/**
* 查询消息内容和数量
*/
export const appMessageGetMessageApi = data => {
  return request({
    url: `/appuser/appMessage/getMessage`,
    method: 'GET',
    data
  })
}
/**
* 查询定制订单消息
*/
export const appMessageGetCustomizeMessageApi = params => {
  return request({
    url: `/appuser/appMessage/getCustomizeMessage`,
    method: 'GET',
    params
  })
}
/**
* 查询平台消息
*/
export const appMessageGetPlatformMessageApi = params => {
  return request({
    url: `/appuser/appMessage/getPlatformMessage`,
    method: 'GET',
    params
  })
}
/**
* 查询现货消息
*/
export const appMessageGetSpotsMessageApi = params => {
  return request({
    url: `/appuser/appMessage/getSpotsMessage`,
    method: 'GET',
    params
  })
}
/**
* 根据id查询消息详情
*/
export const appMessageetMessageByIdApi = params => {
  return request({
    url: `/appuser/appMessage/getMessageById`,
    method: 'GET',
    params
  })
}
/**
* 获取热门搜索列表
*/
export const productGotSearchApi = data => {
  return request({
    url: `/appprod/product/hotSearch`,
    method: 'GET',
    data
  })
}
/**
* 获取相关推荐列表
*/
export const productcorrelativeApi = data => {
  return request({
    url: `/appprod/product/correlative`,
    method: 'GET',
    data
  })
}
/**
* 获取热门城市列表
*/
export const producthotCityApi = data => {
  return request({
    url: `/appprod/product/hotCity`,
    method: 'GET',
    data
  })
}
/**
* 查询产品热门分类列表
*/
export const typehotListApi = data => {
  return request({
    url: `/appprod/type/hotList`,
    method: 'GET',
    data
  })
}
/**
* 查询产品子级分类列表
*/
export const typeSubListApi = params => {
  return request({
    url: `/appprod/type/subList`,
    method: 'GET',
    params
  })
}
/**
* 秒杀时间
*/
export const producseckillTimetApi = data => {
  return request({
    url: `/appprod/product/seckillTime`,
    method: 'GET',
    data
  })
}
/**
* 产品结算页面
*/
export const orderOperationsettlePageApi = params => {
  return request({
    url: `/apporder/orderOperation/settlePage`,
    method: 'GET',
    params
  })
}
/**
* 根据商家编号获取发货方式
*/
export const getDeliveryMethodApi = data => {
  return request({
    url: `/apporder/orderOperation/getDeliveryMethod`,
    method: 'GET',
    data
  })
}
/**
* 获取发票要求
*/
export const getInvoiceMethodApi = params => {
  return request({
    url: `/apporder/orderOperation/getInvoiceMethod`,
    method: 'GET',
    params
  })
}
/**
* 产品结算页面预估价格
*/
export const settlePagePricedApi = data => {
  return request({
    url: `/apporder/orderOperation/settlePagePrice`,
    method: 'POST',
    data
  })
}
/**
* 提交报价需求
*/
export const submitRfqApi = data => {
  return request({
    url: `/apporder/orderOperation/submitRfq`,
    method: 'PUT',
    data
  })
}
/**
* 查询收货地址列表
*/
export const addresslistApi = params => {
  return request({
    url: `/appuser/address/list`,
    method: 'GET',
    params
  })
}
/**
* 修改收货地址
*/
export const editappuseraddressApi = data => {
  return request({
    url: `/appuser/address`,
    method: 'PUT',
    data
  })
}
/**
* 新增收货地址
*/
export const addappuseraddressApi = data => {
  return request({
    url: `/appuser/address`,
    method: 'POST',
    data
  })
}
/**
* 删除收货地址
*/
export const delappuseraddressApi = data => {
  return request({
    url: `/appuser/address/${data}`,
    method: 'DELETE'
  })
}
/**
* 获取收货地址详细信息
*/
export const getappuseraddressApi = data => {
  return request({
    url: `/appuser/address/${data}`,
    method: 'GET',
  })
}
/**
* 根据商家编号获取发货方式
*/
export const getFhfsListApi = params => {
  return request({
    url: `/appsystem/basics/getFhfsList`,
    method: 'GET',
    params
  })
}
/**
* 查询订单列表
*/
export const orderInfoorderListApi = params => {
  return request({
    url: `/apporder/orderInfo/orderList`,
    method: 'GET',
    params
  })
}
/**
* 获取店铺信息
*/
export const tenantinfoApi = data => {
  return request({
    url: `/appsystem/tenant/info/${data}`,
    method: 'GET'
  })
}
/**
* 取消订单
*/
export const cancelOrderApi = data => {
  return request({
    url: `/apporder/orderInfo/cancelOrder?orderId=${data}`,
    method: 'PUT',
  })
}
/**
* 订单支付页
*/
export const payPageAppi = params => {
  return request({
    url: `/apporder/pay/payPage`,
    method: 'GET',
    params
  })
}
/**
* 小程序支付
*/
export const xcxPayInfoAppi = data => {
  return request({
    url: `/apporder/pay/xcxPayInfo`,
    method: 'POST',
    data
  })
}
/**
* 调起支付（微信、支付宝）
*/
export const apporderPayInfoAppi = data => {
  return request({
    url: `/apporder/pay/payInfo`,
    method: 'POST',
    data
  })
}
/**
* 线下支付页
*/
export const btlPayPageAppi = data => {
  return request({
    url: `/apporder/pay/btlPayPage`,
    method: 'POST',
    data
  })
}
/**
* 提交线下支付信息
*/
export const submitBtlPayAppi = data => {
  return request({
    url: `/apporder/pay/submitBtlPay`,
    method: 'PUT',
    data
  })
}
/**
* 查询收藏列表
*/
export const productCollectlistAppi = params => {
  return request({
    url: `/appprod/productCollect/list`,
    method: 'GET',
    params
  })
}
/**
* 根据类型获取字典内容列表
*/
export const selectDictDataByTypeApi = params => {
  return request({
    url: `/appsystem/basics/selectDictDataByType`,
    method: 'GET',
    params
  })
}
/**
* 查看质量异议
*/
export const orderInfogetExceptInfoApi = params => {
  return request({
    url: `/apporder/orderInfo/getExceptInfo`,
    method: 'GET',
    params
  })
}
/**
* 提交质量异议
*/
export const orderInfosubmitExceptApi = data => {
  return request({
    url: `/apporder/orderInfo/submitExcept`,
    method: 'PUT',
    data
  })
}
/**
* 同意质量异议处理结果
*/
export const orderInfoagreeExceptResultApi = data => {
  return request({
    url: `/apporder/orderInfo/agreeExceptResult?expectId=${data}`,
    method: 'PUT'
  })
}
/**
* 确认收货
*/
export const orderInfocompleteApi = data => {
  return request({
    url: `/apporder/orderInfo/complete?stockUpId=${data}`,
    method: 'PUT'
  })
}
/**
* 编辑提货单
*/
export const orderInfosubmitStockUpApi = data => {
  return request({
    url: `/apporder/orderInfo/submitStockUp`,
    method: 'PUT',
    data
  })
}
/**
* 查询电子提货单详情
*/
export const orderInfoorderStockUpDetailApi = data => {
  return request({
    url: `/apporder/orderInfo/orderStockUpDetail?stockUpId=${data}`,
    method: 'GET'
  })
}
/**
* 订单评价
*/
export const orderInfosubmitEvaluateApi = data => {
  return request({
    url: `/apporder/orderInfo/submitEvaluate`,
    method: 'PUT',
    data
  })
}
/**
* 新增申请售后
*/
export const afterSalesaddApi = data => {
  return request({
    url: `/apporder/afterSales/add`,
    method: 'POST',
    data
  })
}
/**
* 获取订单详情
*/
export const orderInfoorderDetailApi = data => {
  return request({
    url: `/apporder/orderInfo/orderDetail?orderId=${data}`,
    method: 'GET'
  })
}
/**
* 获取洽谈详细信息
*/
export const orderInfogetTalkInfoApi = data => {
  return request({
    url: `/apporder/orderInfo/getTalkInfo?orderId=${data}`,
    method: 'GET'
  })
}
/**
* 编辑洽谈需求-页面
*/
export const orderInfogoOrderTalkPageApi = data => {
  return request({
    url: `/apporder/orderInfo/goOrderTalkPage?orderId=${data}`,
    method: 'GET'
  })
}
/**
* 编辑洽谈需求
*/
export const orderInfoeditSkuApi = data => {
  return request({
    url: `/apporder/orderInfo/editSku`,
    method: 'PUT',
    data
  })
}
/**
* 确认洽谈需求
*/
export const orderInfoaffirmTalkApi = data => {
  return request({
    url: `/apporder/orderInfo/affirmTalk?orderId=${data}`,
    method: 'PUT',
  })
}
/**
* 查询产品评价列表
*/
export const orderEvaluatelistApi = data => {
  return request({
    url: `/apporder/orderEvaluate/list`,
    method: 'GET',
    data
  })
}
/**
* 查看物流
*/
export const getLogisticsList = data => {
  return request({
    url: `/apporder/orderInfo/getLogisticsList?stockUpId=`+data,
    method: 'GET'
  })
}
/**
* 多图上传
*/
export const uploadPicsUrl = 'resource/upload/pics'

/**
* 文件上传
*/
export const uploadFileUrl = 'resource/upload/file'